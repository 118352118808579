import React from 'react';
import './NavBar.css';
import { Container } from 'react-bootstrap';
import logo from '../../images/logo.svg';

const NavBar = () => {
    const pathName = window.location.pathname;

    return (
        <div className={pathName === '/terms&condition' || pathName === '/privacy&policy' ?'bg-dark' : ''}>
            <Container>
            <nav className="navbar navbar-expand-lg fullNavBar">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src={logo} alt="" className='navLogo'/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <div id="nav-icon">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto NavBar">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/#services">Our Services</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/#partner">Benefits</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/#contact">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            </Container>
        </div>
    );
};

export default NavBar;