import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './Footer.css';
import zopnikIcon from '../../images/zopnikIcon.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';


const Footer = () => {
    return (
        <div>
            <section id='footer'>
                <Row className='zopnikIcon col-md-11 mx-auto'>
                    <Col md={6} lg={5}>
                        <div className='address'>
                            <img src={zopnikIcon} alt="" />
                            <div className="d-flex align-items-center">
                                <div className='footerDetails'>
                                    <h6>Zopnik Innovations Pvt Ltd</h6>
                                    <p>11, Ground Floor, Oak Drive DLF Farms, Chattarpur Delhi - 110074</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} lg={2}>
                        <div className="contactDetails">
                            <span><FontAwesomeIcon className="icon" icon={faPhoneAlt} /> +91- 8130399172</span>
                            <span><FontAwesomeIcon className="icon" icon={faEnvelope} /> help@zopnik.com</span>
                            
                        </div>
                    </Col>
                    <Col md={6} lg={2}>
                        <div className="pageLinks">
                            <div>
                                <a target="_blank" href="/privacy&policy">Privacy Policy</a>
                                <a target="_blank" href="/terms&condition">Terms & Conditions</a>
          
                            </div>
                        </div>
                    </Col>
                    <Col md={6} lg={3}>
                        <div className="allSocialIcons">
                            <a target="_blank" href="https://www.facebook.com/ZopnikApp">
                                <FontAwesomeIcon className="icon active-icon" icon={faFacebookF} />
                            </a>
                            <a target="_blank" href="https://www.instagram.com/zopnikapp/">
                                <FontAwesomeIcon className="icon" icon={faInstagram} />
                            </a>
                            <a target="_blank" href="https://twitter.com/ZopnikIndia">
                                <FontAwesomeIcon className="icon" icon={faTwitter} />
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/company/zopnik/">
                                <FontAwesomeIcon className="icon" icon={faLinkedinIn} />
                            </a>
                        </div>
                    </Col>
                </Row>
            </section>
            <div className='copyright'>
                <p>Copyright © {new Date().getFullYear()} Zopnik | All Rights Reserved</p>
            </div>
        </div>
        
    );
};

export default Footer;