import React, { useState } from 'react';
import './Contact.css'
import { Col, Row, Container, Form, Spinner } from 'react-bootstrap';
import contactImg from '../../images/contact.svg'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';


const Contact = () => {
    const { register, handleSubmit, reset } = useForm();
    const [isSubmit, setSubmit] = useState(false)

    const onSubmit = data => {
        setSubmit(true)
        axios.post('https://zopnik.com/api/admin/sendEmail', data)
        .then(res => {
            if(res.data){
                swal("Success!", "Thank you for connecting with us. We will get back to you shortly!", "success");
                reset()
                setSubmit(false)
            }
        })
    }

    

    return (
        <section id="contact">
            <Container>
                <h2 className='secHeader'>Get In Touch</h2>
                <p className='secP'>And Know More About How We Can Make Design To Delivery Simpler For You</p>
                <Row className="align-items-center">
                    <Col md={6}>
                        <Form onSubmit={handleSubmit(onSubmit)} >
                            <Row className='contactForm'>
                                <Col md={12} lg={6}>
                                    <Form.Control size="lg" className='my-2' type="text" placeholder="Name" {...register("name", { required: true })}/>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Form.Control size="lg" className='my-2' type="text" placeholder="Phone Number" {...register("phone", { required: true })} />
                                </Col>
                                <Col md={12} lg={6}>
                                    <Form.Control size="lg" className='my-2' type="text" placeholder="Email"{...register("email", { required: true })}/>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Form.Control size="lg" className='my-2' type="text" placeholder="Company" {...register("company", { required: true })}/>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Form.Control size="lg" className='my-2' type="text" placeholder="Country" {...register("country", { required: true })}/>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Form.Select size="lg" className='my-2 form-control' {...register("type", { required: true })}>
                                        <option selected="true" disabled="disabled">Select</option>
                                        <option>Buyer</option>
                                        <option>Factory</option>
                                    </Form.Select>
                                </Col>
                                <Col md={12}>
                                    <Form.Control size="lg" className='my-2 commentBox' type="text" placeholder="Comments"  as="textarea" {...register("comments", { required: true })}/>
                                </Col>
                            </Row>
                            <div className="contactBtnBox">
                                {
                                    isSubmit ? <Spinner animation="border" />:
                                    <button type='submit' className='submitBtn'>Submit</button>
                                }
                            </div>
                        </Form>
                    </Col>
                    <Col md={6}>
                        <div className="contactImg">
                            <img src={contactImg} alt="" className="img-fluid"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Contact;