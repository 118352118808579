import logo1 from '../images/design.png';
import logo2 from '../images/production.png';
import logo3 from '../images/sample.png';
import logo4 from '../images/delivery.png';
import logo5 from '../images/assurance.png';
import logo6 from '../images/design1.png';
import logo7 from '../images/production1.png';
import logo8 from '../images/sample1.png';
import logo9 from '../images/delivery1.png';
import logo10 from '../images/assurance1.png';

export const services = [
    {
    title: 'Design & Development',
    logo: logo1,
    logo2: logo6,
    description: 'Design support right from research in trend spotting & inspiration to creating a collection',
    },
    {
    title: 'Production',
    logo: logo2,
    logo2: logo7,
    description: 'Production support with our wide range of global manufacturing partners with all kinds of order sizes',
    },
    {
    title: 'Sampling',
    logo: logo3,
    logo2: logo8,
    description: 'Sample development support right from proto stage till the production stage -- all in the single interface ',
    },
    {
    title: 'Delivery',
    logo: logo4,
    logo2: logo9,
    description: 'Provide logistic support through a wide network of third party logistic partners',
    },
    {
    title: 'Quality Assurance',
    logo: logo5,
    logo2: logo10,
    description: 'Ensure quality standards in production from AQL 1.0 - 2.5 based on brand requirement',
    }
]