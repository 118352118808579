import logo1 from '../images/box.png';
import logo2 from '../images/lead-time.png';
import logo3 from '../images/market-trends.png';
import logo4 from '../images/capital.png';
import logo5 from '../images/communication.png';

export const partner = [
    {
    title: 'Lowest MOQ',
    logo: logo1,
    description: 'Minimum Order Quantity starts from as low as 100 pcs',
    },
    {
    title: 'Production Lead Time',
    logo: logo2,
    description: 'Production lead time as low as 4 weeks',
    },
    {
    title: 'Market Intel',
    logo: logo3,
    description: 'Single platform to understand the market trend and product performance globally',
    },
    {
    title: 'Working Capital Management',
    logo: logo4,
    description: 'Better working capital cycle due to rotation of inventory',
    },
    {
    title: 'Communication',
    logo: logo5,
    description: 'Zero follow ups on an order ensure a brand focus on sales',
    }
]