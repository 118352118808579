import React from 'react';
import { Container } from 'react-bootstrap';
import { partner } from '../../data/partner.js';
import './Partner.css'

const Partner = () => {
    return (
        <section id="partner">
            <Container>
                <h2 className='partnerHeader'>Benefits for our brand partners</h2>
                <p className='partnerP'>Zopnik helps you to access global trends and tap category performances to your best working capital capability</p>
                <div className='partnerCards'>
                    {
                        partner.map(({title, description, logo}, index) => (
                            <div className='partnerCard'>
                                <div>
                                    <div className='partnerImgCard'>
                                        <img src={logo} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <h4>{title}</h4>
                                    <p>{description}.</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Container>
        </section>
    );
};

export default Partner;