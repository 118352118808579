import React from 'react';
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import './Terms.css'

const Terms = () => {

    return (
        <div>
            <NavBar/>
            <div className='terms'>
                <h2>Terms & Conditions</h2>
                <p>Zopnik is an application which provides an online marketplace (“Application”) where registered suppliers (“Suppliers”) can offer to sell their respective products to registered users of the Application (“Resellers”). The Application and the website www.zopnik.com (“Website”) (collectively, the “Platform”) are operated by Zopnik Innovations Pvt Ltd (including its directors, full time employees, subsidiaries and affiliates, collectively, the “Company”). The Company’s role is limited to the managing Application and associated marketing, payment collections, order management, enquiry management and other incidental services to enable the transactions between the Suppliers and the Reseller (“Business”). The Application is not made available on a browser environment and will have to be installed as a standalone mobile application.</p>
                <p>By installing, downloading or even merely visiting or accessing any part of the Application or utilising the Services and accessing the Website, users, including without limitation users who are browsers, Suppliers, Resellers, merchants, or contributors of content (collectively, “User”) agree to be bound by the Terms of Use (“Terms”) contained herein and by other policies of the Company (“Policies”) as posted on the Application or Website from time to time. References to the User in these Terms must be construed in the context in which the term is used. For instance, in these Terms, a User placing an order should be read as the Reseller placing an order.</p>
                <p>The Terms and the Policies take effect on the date on which the Application is downloaded/Website is used and/or the date on which they are updated, creating a legally binding arrangement between the User and the Company. The User’s agreement with the Company includes these Terms and the privacy policy (“Privacy Policy”) available at https://zopnik.com./policy (collectively the “Agreement”).</p>
                <p>Users can review the most current version of the Terms and Policies at any time on this page. The Company reserves the right to unilaterally update, change or replace any part of these Terms by posting updates or changes to the Application. It is the responsibility of the Users to check this page periodically for changes. The Users continued use of or access to the Application following the posting of any changes constitutes acceptance of those changes. As long as the User complies with these Terms, the Company grants the User a personal, non-exclusive, non-transferable, limited privilege to enter and use our Platforms and services.
                </p>
                <p>IF THE USER DOES NOT AGREE WITH THESE TERMS, THE USER IS ADVISED TO REFRAIN FROM USING THE PLATFORM. BY ACCESSING OR USING THE PLATFORM, THE USER IRREVOCABLY ACCEPTS THE AGREEMENT AND AGREES TO ABIDE BY THE SAME (AS UPDATED FROM TIME TO TIME).</p>
                <h3>USER ELIGIBILITY</h3>
                <p>User(s) represent and warrant that they have the right to access or use the Platform. Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to access and use the Platform. The Platform can be accessed and used by those individuals or business entities, including sole proprietorship firms, companies and partnerships, which can form legally binding contracts under Indian Contract Act, 1872. Only individuals who are eighteen (18) years of age or older may use the Application and avail Services. The Company reserves the right to terminate the Users account and / or deny access to the Platform if it is brought to the Company’s notice or if is discovered that the User does not meet the conditions herein.</p>
                <h3>ACCOUNT REGISTRATION</h3>
                <p>The Company does not permit Users to access and view the contents of the Application without registration on the Application. Users may access the Application by registering to create an account and become a member. The membership is limited for the purpose of buying or selling products, is subject to these Terms, and strictly not transferable.</p>
                <p>The Users agree to provide accurate, current and complete information during the registration process and to update such information to keep it accurate, current and complete.
                    The Users are required to enter a valid phone number while registering on the Company’s Platforms. By registering their phone numbers with the Company, the Users consent to be contacted by the Company via phone calls, SMS notifications or instant messages, in case of verifications and in case of subscription/service/promotional updates. The Users may opt of subscription/service/promotional updates.
                    Upon registration, the Users may be required to complete a verification process as part of setting up their accounts. Once set up, the Users are responsible for maintaining the confidentiality of their account information, and are fully responsible for all activities that occur through their accounts. Should there be instances of any unauthorized use of their accounts or any other breach of security, the Users are required to notify the Company to stop processing requests from their accounts, until further instructions.</p>
                <p>It is the responsibility of the Users to provide correct mobile number so that the Company can communicate with the Users via SMS. The Users understand and agree that if the Company sends an SMS but the Users do not receive it because the Users’ mobile number is incorrect or out of date or blocked by the Users; service provider, or the Users are otherwise unable to receive SMS, the Company shall be deemed to have provided the communication to the Users effectively.</p>
                <p>The Company reserves the right to suspend or terminate the account or access to the Application (i) if any information provided during the registration process or thereafter proves to be inaccurate, not current or incomplete; and/or (ii) if the Users are found to be non-compliant with the Terms, Policies and/or the Privacy Policy as the case may be.</p>
                <p>On registration, the Users will receive a password protected account and an identification. The Users agree to: (i) maintain the confidentiality of their password; (ii) take full responsibility for all activities by Users accessing the Application through their account; (iii) immediately notify the Company of any unauthorised use of their account or any other breach of security that they become aware of; and (iv) ensure that they exit from their account at the end of each session. The Users are solely responsible for all activities that occur under their account and that all purchases made by them are intended for sale or consumption in the course of their business activities. It shall be the responsibility of the User to treat the user identification code, password and any other piece of information that is provided by the Company, as part of the security procedures, as confidential and not disclose the same to any person or entity other than the Company. The Company shall at times and at their sole discretion reserve the right to disable any user identification code or password if the Users have failed to comply with any of the provisions of these Terms, Policies and/or the Privacy Policy as the case may be. Notwithstanding anything to the contrary herein, the Users acknowledge and agree that they shall have no ownership or other property interest in their account, and further acknowledge and agree that all rights in and to their account are and shall forever be owned by and inure to the benefit of the Company. However, any and every activity undertaken by a User under his/her account shall be the sole responsibility of such User and the Company shall not be liable for such activity in any manner.</p>
                <h3>AMENDMENT TO THE TERMS</h3>
                <p>The Company reserves the right to change, modify, amend, or update these Terms and/ or the Agreement from time to time and such amended provisions of these Terms and/ or the Agreement shall be effective immediately upon being posted on the Platform. If the Users do not agree with such provisions, the Users must stop using the Platform with immediate effect. The Users’ continued use of the Platform will be deemed to signify their acceptance of the amended provisions of these Terms and/ or the Agreement.</p>
                <h3>Orders and Financial Terms</h3>
                <p>The Application allows Users to place orders for the products sold by various Suppliers and the Company will, subject to the terms and conditions set out herein, facilitate the placement of orders for the products to the Users. The Company does not own, sell, resell products on its own and/or does not control the Suppliers. The Company reserves the right to delist any product from the Application.
                    The Users understand that any order that they place shall be subject to the terms and conditions set out in these Terms, and any terms and conditions imposed by the Suppliers concerned.
                    On receipt of an order from a User, the Company shall send electronically a confirmation of such order to the Supplier and the User concerned. Further, the Company may inform the Users about the availability or unavailability or change in price of the order as informed by the Supplier concerned. Confirmation of the order shall be treated as final.</p>
                <p>All commercial/contractual terms are offered by and agreed to between Suppliers and the Users alone. The commercial/contractual terms include without limitation, price, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products, etc. The Company does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Suppliers and the Users. All discounts and offers are by the Suppliers and not by the Company.</p>
                <p>The Users acknowledge and agree that the Company may, at the request of the Supplier, act as the payment agent for the limited purpose of accepting payments from them on behalf of the Supplier. Upon payment of the amounts to the Company, which are due to the Supplier, the payment obligation to the Supplier for such amounts is completed, and the Company will be responsible for remitting such amounts, to the seller. The Users understand, accept and agree that the payment facility provided by the Company is neither a banking nor financial service but is merely a facilitator providing a third party payment processor for the transactions on the Application. Further, by providing payment facility, the Company is neither acting as a trustee nor acting in a fiduciary capacity with respect to the transaction or the transaction price. The Company will not be liable for any charges made by the Users bank in relation to payment of the total amount.
                    The Users agree to provide current, complete and accurate purchase and account information for all purchases made at on the Application. The Company agrees to promptly update the Users account and other information, including email addresses and credit card numbers and expiration dates, so that the Company can complete the transactions.
                    In connection with any order, information such as name, billing address and credit card information will have to be provided either to the Company or the third party payment processor. If the Users are directed to the third party payment processor, they may be subject to terms and conditions governing use of that third party’s service and that third party’s personal information collection practices. Users are requested to review such terms and conditions and privacy policy before using the Application.
                    The Company merely collects the payment on behalf of the Supplier. All applicable taxes and levies, the rates thereof and the manner of applicability of such taxes on the documents are being charged and determined by the Supplier. The Company holds no responsibility for the legal correctness/validity of the levy of such taxes. The sole liability with respect to any legal issue arising on the taxes payable shall be with the Supplier. The transaction is bilateral between the Suppliers and the Users and, the Company is not liable to charge or deposit any taxes applicable on such transaction.
                    The Suppliers are bound by, including without limitation, the following laws:
                    a. Metrology Act and Legal Metrology (Packaged Commodities) Rules, 2011 (Packaging Rules); As per above mentioned acts and regulations and any other relevant law in place during the tenure of this association, the Company understands that there is an obligation on the Supplier to ensure that the package in which the products are sold complies with labelling and packing requirements and other laws that may be prescribed in this regard. Hence, it shall be the sole responsibility of the Supplier to comply with applicable laws and the Company shall not be held responsible in any manner. Suppliers shall indemnify the Company and the Platform for any harm or loss in relation to contravention of above regulations</p>
                <h3>Use of the Application</h3>
                <p>The Users agree, undertake and confirm that their use of the Platform shall be strictly governed by these Terms, Policies and the Privacy Policy. The Users shall not host, display, upload, download, modify, publish, transmit, update or share any information which:</p>
                <p>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, slanderous, criminally inciting or invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever; or unlawfully threatening or unlawfully harassing including but not limited to “indecent representation of women” within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;
                    is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual; harasses or advocates harassment of another person;
                    infringes upon or violates any third party’s rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person’s name, email address, physical address or phone number) or rights of publicity;
                    promotes an illegal or unauthorized copy of another person's copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices;
                    tries to gain unauthorized access or exceeds the scope of authorized access to the Application or to the profiles, blogs, communities, account information, or other areas of the Application or solicits passwords or personal identifying information for commercial or unlawful purposes from other Users;
                    interferes with another User’s use and enjoyment of the Platform or any third party users enjoyment of similar services;
                    refers to any website or URL that, in our sole discretion, contains material that is inappropriate for the Platform or any other website, contains content that would be prohibited or violates the spirit of these Terms; violates any law for the time being in force;
                    impersonates another person;
                    contains software viruses or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information; and directly or indirectly, offers, attempts to offer, trades or attempts to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.
                    By using the Platform the Users represent and warrant that:
                    All registration information submitted by the Users is truthful, lawful and accurate;
                    The Users use of the Application shall be solely for their use and they shall not authorize others to use the account; The Users will not submit, post, upload, distribute, or otherwise make available or transmit any information that: (a) is defamatory, abusive, harassing, insulting, threatening, or that could be deemed to be stalking or constitute an invasion of a right of privacy of another person; (b) is bigoted, hateful, or racially or otherwise offensive; (c) is violent, vulgar, obscene, pornographic or otherwise sexually explicit; (d) is illegal or encourages or advocates illegal activity or the discussion of illegal activities with the intent to commit them;
                    All necessary licenses, consents, permissions and rights are owned by the Users and there is no need for any payment or permission or authorization required from any other party or entity to use, distribute or otherwise exploit in all manners permitted by these Terms, Policies and Zopnik Policy, all trademarks, copyrights, patents, trade secrets, privacy and publicity rights and / or other proprietary rights contained in any Content that the Users submit, post, upload, distribute or otherwise transmit or make available;
                    The Users will not use the Application in any way that is unlawful, or harms the Company or any other person or entity;
                    The Users will not post, submit, upload, distribute, or otherwise transmit or make available any software or other computer files that contain a virus or other harmful component, or otherwise impair or damage the Application or any connected network, or otherwise interfere with any person or entity’s use or enjoyment of the Application;
                    The Users will not use another person’s username, password or other account information, or another person’s name, likeness, voice, image or photograph or impersonate any person or entity or misrepresent your identity or affiliation with any person or entity;
                    The Users will not delete or modify any content of the Application, including but not limited to, disclaimers or proprietary notices such as copyright or trademark symbols, logos;
                    The Users will not delete or modify any content of the Application, including but not limited to, disclaimers or proprietary notices such as copyright or trademark symbols, logos;
                    The Users shall not access the Application without authority or use the Application in a manner that damages, interferes or disrupts, any part of the Application or any equipment or any network on which the Application is stored or any equipment of any third party;
                    The Users will always be in compliance with applicable laws;
                    The Users release and fully indemnify the Company and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the Application and specifically waive any claims that the Users may have in this behalf under any applicable laws of India. Notwithstanding its reasonable efforts in that behalf, the Company cannot take responsibility or control the information provided by other Users which is made available on the Application.
                    The Users shall not attempt to gain unauthorized access to any portion or feature of the Application, or any other systems or networks connected to the Application by any means. The Users shall not probe, scan or test the vulnerability of the Application nor breach the security or authentication measures on the Application or any network connected to the Application.
                    The Users agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Application or any transaction being conducted on the Application, or with any other person’s use of the Application. The Users may not use the Application or any of its content for any purpose that is unlawful or prohibited by these Terms, Policies or the Privacy Policy.
                    The Users shall at all times ensure full compliance with the applicable provisions, as amended from time to time, including that of (a) the Information Technology Act, 2000 and the rules thereunder; (b) all applicable domestic laws, rules and regulations (including the provisions of any applicable exchange control laws or regulations in force); and (c) international laws, foreign exchange laws, statutes, ordinances and regulations (including, but not limited to Direct and Indirect Taxes applicable as per current statue in the country) regarding the use of the Application and listing, purchase, solicitation of offers to purchase, and sale of products or Services. The Users shall not engage in any transaction which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.
                    In order to allow the Company to use the information supplied by the Users, without violating any rights or any laws, the Users agree to grant the Company a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights. The Company will only use the information in accordance with these Terms of Service and Privacy Policy available at https://zopnik.com/privacy, applicable to use of the Application.
                    From time to time, the Suppliers shall be responsible for providing information relating to the products proposed to be sold by them. In this connection, the Suppliers undertake that all such information shall be accurate in all respects. The Suppliers shall not exaggerate or overemphasize the attributes of such products so as to mislead other Users in any manner.
                    The Company reserves the right, but has no obligation, to monitor the materials posted on the Application. The Company shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit of these Terms. In no event shall the Company assume any responsibility or liability for any content posted or for any claims, damages or losses resulting from use of content and/or appearance of content on the Application.
                    The Company shall have all the rights to take necessary action and claim damages that may occur due to the Users involvement/participation in any way either on their own or through group/s of people, intentionally or unintentionally in hacking.
                    The Users understand that their content, may be transferred, unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.
                    The Users understand and acknowledge that the use of the Application requires internet connectivity and telecommunication links. The Users shall bear the costs incurred to access and use the Application and avail the Services, and the Company shall not, under any circumstances whatsoever, be responsible or liable for such costs.</p>
                <h3>ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h3>
                <p>The Company is not responsible for any inaccuracy, incompleteness or outdated information made available on the Application, either provided by the Users or the Suppliers. The material on the Application is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. The Users agree that, the Company does not own any responsibility or obligation whatsoever towards either ensuring the accuracy of the information provided by the Users. Any reliance on the material on the Application is at the Users’ own risk.
                    The Application may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. The Company reserves the right to modify the contents of the Application at any time, but has no obligation to update any information on the Application. The Users agree that it is their responsibility to monitor changes to the Application.
                    Occasionally there may be information on the Application that contains typographical errors, inaccuracies or omissions that may relate to information pertaining to the products, pricing, promotions, offers, shipping charges, transit times and availability. The Company reserves the right to correct any errors, inaccuracies or omissions, and to change or update information if any information on the Application is inaccurate at any time without prior notice. The Company undertakes no obligation to update, amend or clarify information in the Application, including without limitation, pricing information, except as required by law. No specified update or refresh date applied to the Application, should be taken to indicate that all information on the Application or pertaining to the Services have been modified or updated.
                    DISCLAIMERS OF WARRANTY AND LIMITATION OF LIABILITY
                    The Company endeavours to make the Application available during the Company’s working hours. However, the Company does not represent that access to the Application will be uninterrupted, timely, error free, free of viruses or other harmful components or that such defects will be corrected.
                    The Company does not warrant that the Application will be compatible with all hardware and software which is used by the Users. The Company shall not be liable for damage to, or viruses or other code that may affect, any equipment, software, data or other property as a result of downloading and installing the Application. The Company does not represent or warrant that the information available on the Application will be correct, accurate or otherwise reliable.
                    The Suppliers shall be solely responsible about the details pertaining to specifics (such as quality, value, saleability, etc) of the products proposed to be sold or offered to be sold or purchased on the Application. The Company does not implicitly or explicitly support or endorse the sale or purchase of any products nor provide any warrantee/guarantee of the products sold to the Users, and in no event shall such products be the responsibility of the Company.
                    The Company is not responsible for any non-performance or breach of any contract entered into between the Suppliers and the Users. The Company cannot and does not guarantee that the concerned Suppliers will perform any transaction concluded on the Application. The Company shall not and is not required to mediate or resolve any dispute or disagreement between the Users concerned.
                    The Company does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc) of any of its Users.
                    The Company does not at any point of time during any transaction between any Supplier and a User take possession of any of the products offered nor does it at any point gain title to or have any rights or claims over such products. At no time shall the Company hold any right, title or interest over the products nor shall the Company have any obligations or liabilities in respect of such contract entered into between the Users. The Company is not responsible for damages or delays as a result of products which are out of stock, unavailable or back ordered.
                    The Company only provides a Platform for communication and it is agreed that the contract for sale of any of the products shall be a strictly bipartite contract between the Suppliers and the Users concerned.
                    The Company shall not be liable for any misuse of the information shared by the Users with it; or through the Users profile; or with a third party on the Platform, chat rooms, forums, or comments.
                    The Application may be under constant upgrades, and some functions and features may not be fully operational.
                    The Application is provided on an “as is” and “as available” basis. The Company expressly disclaims all warranties of any kind, whether express or implied with respect to the records and other data that is made available by it to the Users.
                    The Company makes no representation or warranty that: (i) the Application will be accurate or reliable; (ii) the Application will be uninterrupted, timely, secure, or error-free; (iii) any information that may be obtained from the use of the Application will be accurate, timely or complete; or (iv) any errors in any software used on the site or in connection with the Application will be corrected.
                    The Company does not represent any of the Users or Suppliers, and disclaims any liability with respect to any error or inconsistency with respect to any information relating to such Suppliers or Users displayed on the site. Any information provided with respect to the Users and fees payable is subject to change without notice. Any trademark, word mark or intellectual property of any Users or Suppliers belongs to such Users/Suppliers alone, and the Company has no right or claim over the same.
                    Users acknowledge and agree that the Company is not an arbitrator or judge of disputes concerning intellectual property and it cannot, by any means, verify that any Supplier selling or supplying merchandise on the Platform have the right to sell the products. The Company encourages Users to assist it in identifying listings on the Platform, which, according to the Users’ knowledge or belief infringe their rights or third party rights.
                    The Users further acknowledge and agree that by taking down a listing, the Company does not and cannot be deemed to be endorsing a claim of infringement and further in those instances in which the Company declines to take down a listing, the Company does not and cannot be deemed to be endorsing that the listing is not infringing of third party rights or endorsing any sale or supply of merchandise or services pursuant to or on account of such listing.
                    The Company reserves the right in its sole discretion to remove any material/content/photos/offers displayed on the Platform which in the Company’s reasonable belief is unlawful or could subject the Company to liability or is in violation of these Terms or is otherwise found inappropriate in the Company’s opinion. The Company reserves the right to cooperate with any investigation in this regard.
                    The Company reserves the right to suspend or terminate the account of a User as deemed appropriate by it. Users agree that the Company shall have no liability to any Users, including liability in respect of consequential or any other damages, in the event the Company takes any of the actions mentioned in this clause.
                    The Company is not responsible and will have no liability for: (i) any content or products provided by any persons or entities other than the Company; (ii) damages of any kind that result from the downloading of any data or any other materials on the site or through the Application; or (iii) the failures of the internet or any data or telecommunications equipment, system or network used in connection with the Application.
                    The Company shall not be liable for: any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation any financial losses, loss of data, replacement costs, or any similar damages, whether based in contract, tort, strict liability or otherwise, arising from the use of the Application, or for any other claim related in any way to the use of the Application, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Application or any content posted, transmitted, or otherwise made available via the Application, even if advised of their possibility.
                    The Company or its employees, affiliates, authors or agents shall not be liable to any party for any losses or injury arising out of or relating to the information provided on the Application. In no event will the Company or its employees, affiliates, authors or agents be liable to the Users or any third party for any decision made or action taken by the Users.</p>
                <h3>Interlia, the Company does not guarantee that:</h3>
                <p>The Platform will meet the Users’ expectations; or
                    The Platform will be accessible without interruption or in a timely, reliable, or fault-free manner; or
                    The results obtained through use of the Platform will be correct and reliable; or
                    The quality of the products, services, information, or other material purchased or obtained by the User through the Platform will meet the User’s expectations.
                    The Users shall be solely responsible for damages to their data system or for loss of data arising from download of content from the Application.
                    No guidance or information, written or oral, obtained from the Company or via the Platform, shall constitute any warranty, unless stated otherwise.</p>
                <h3>SELLING</h3>
                <p>Suppliers are permitted to list products for sale on the Application in accordance with the Terms and Policies which are incorporated by way of reference in this Terms of Service. The Suppliers represent and warrant that they are legally able to sell or list the products on the Application; and the listed items do not infringe upon the intellectual property, trade secret or other proprietary rights or rights of publicity or privacy rights of third parties. The Suppliers and the Users agree that the Company is not responsible for</p>
                <h3>PRIVACY</h3>
                <p>All information about Users that are collected, stored or transmitted in any way on the Application, including any registration information, is subject to our Privacy Policy (the “Privacy Policy”), available at Privacy Policy (pls add the link)
                    The information collected by the Company through the Application includes Users’ mobile number, bank details and KYC for the limited purpose of fulfilling transactions on the Application. Compilation of user accounts and user accounts bearing contact number and e-mail addresses are owned by the Company.
                    The Users agree that information about their use of the Platform through their mobile telecommunication device may be communicated to the Company, and the Company may obtain information from the Users’ mobile carrier or mobile device. In addition, use of the Platform through a mobile telecommunication device may cause data to be displayed on and through the Users’ mobile device. By accessing the Platform using a mobile telecommunication device, the Users represent that to the extent they import any of their data to their mobile telecommunication device they have authority to share the transferred data with their mobile carrier or other access provider.
                    The Users agree that information about their use of the Platform through their mobile telecommunication device may be communicated to the Company, and the Company may obtain information from the Users’ mobile carrier or mobile device. In addition, use of the Platform through a mobile telecommunication device may cause data to be displayed on and through the Users’ mobile device. By accessing the Platform using a mobile telecommunication device, the Users represent that to the extent they import any of their data to their mobile telecommunication device they have authority to share the transferred data with their mobile carrier or other access provider.
                    The Users acknowledge that they are responsible for all charges and necessary permissions related to accessing the Platform through their mobile access provider. The Company urges the Users to check with their providers to find out if the Platform is available on their mobile devices.
                    DISCLOSURE
                    The Users acknowledge, consent and agree that the Company may access, preserve and disclose their account information if required to do so by law or in a good faith belief that such access, preservation or disclosure is reasonably necessary to:
                    comply with legal process nationally or internationally;
                    enforce these Terms;
                    respond to your requests for service or complete your tasks;
                    protect the rights, property or personal safety of the Company, its subscribers and the public, or pursuant to the terms of the Privacy Policy.</p>
                <h3>THIRD PARTY LINKS</h3>
                <p>Certain content or products available via the Application may include materials from third-parties.
                    Third-party links on the Application may direct the Users to third-party websites that are not affiliated with the Company. The Company is not responsible for examining or evaluating the content or accuracy and does not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
                    The Company is not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party’s policies and practices and make sure to understand them before engaging in any transactions. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>
                <h3>OPTIONAL TOOLS</h3>
                <p>The Company may provide you with access to third-party tools over which Company neither monitors nor has any control nor input.
                    The Users acknowledge and agree that access to such tools is in an “as is” and “as available” basis, without any warranties, representations or conditions of any kind and without any endorsement. The Company shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
                    The Users acknowledge and agree that access to such tools is in an “as is” and “as available” basis, without any warranties, representations or conditions of any kind and without any endorsement. The Company shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
                    The Company may also, in the future, offer new features through the Application (including, the release of new tools and resources). Such new features shall also be subject to these Terms of Service.
                    SECURITY COMPONENTS
                    The Users understand that the Platform and software embodied within the Platform may include security components that permit digital materials to be protected, and that use of these materials is subject to usage rules set by the Company or other parties that facilitate the same. The Users agree that they will not attempt to override, disable, circumvent or otherwise interfere with any such security components and usage rules embedded in the Platform.</p>
                <h3>INTELLECTUAL PROPERTY</h3>
                <p>The Company is either the owner of intellectual property rights or has the non-exclusive, worldwide, perpetual, irrevocable, royalty free, sub-licensable (through multiple tiers) right to exercise the intellectual property, on the Application, and on the material published on it in cases where it may be so construed. The Users acknowledge that the copyright in the information shared with the Company by the Suppliers or other content providers may vest in such persons and not in the Company unless otherwise stated.
                    “Zopnik” and related icons and logos whether registered or unregistered are the trademarks of the Company and are protected under applicable copyright, trademark and other proprietary and intellectual property laws. Users’ unauthorized adoption copying, modification, use or publication of these marks is strictly prohibited.
                    All Content including Information (defined below) is copyrighted to the Company excluding any third-party content including without limitation, content shared by Suppliers and any links to any third-party websites being made available or contained on the Platform. Users may not use any trademark, service mark or logo of any independent third parties without prior written approval from such parties.
                    The Users must not modify the paper or digital copies of any materials printed or downloaded in any way, and they must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
                    The Users must not use any part of the materials on the Application for commercial purposes without obtaining a license to do so from the Company.
                    All rights, not otherwise claimed under these Terms by the Company are hereby reserved.
                    Any information or advertisements contained on, distributed through, or linked, downloaded or accessed from the Platform or any offer displayed on the Platform (“Information”) is intended, solely to provide general information for the personal use of the Users, who fully accept any and all responsibility and liabilities arising from and out of the use of such Information.
                    The Company does not represent, warrant or endorse in any manner the accuracy or reliability of such Information, or the quality of any products and/or services obtained by the Users as a result of any such Information.
                    The Information is provided “as is” with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of the Information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose. Nothing contained in these Terms shall to any extent substitute for the independent investigations and the sound technical and business judgment of the Users.
                    In no event shall the Company be liable for any direct, indirect, incidental, punitive, or consequential damages of any kind whatsoever with respect to the User(s) use of such products.</p>
                <h3>COMMUNICATIONS</h3>
                <p>The Company urges the users to beware of fake offers and fraudulent callers/messengers who may impersonate themselves as representatives of the Company. The Company’s authorised representatives will never contact the Users to demand money for prizes or ask for password/PIN/CVV. In the event you are asked for confidential details by anyone posing as the Company’s representatives, please ask them to communicate with you through email and only respond to emails from www.zopnik.com domain.
                    FORCE MAJEURE
                    The Company shall not be liable for any damages whatsoever arising out of force majeure or other similar circumstances, directly or indirectly affecting the Company and/or the Platform. Examples of force majeure events include without limitation real or potential labour disputes, governmental actions, war or threat of war, sabotage, civil unrest, demonstrations, fire, storm, flooding, explosion, earthquake, provisions or limitations of materials or resources, inability to obtain the relevant authorization, accident, and defect in electricity or telecommunication network. Force majeure or other events beyond the Company’s control. Hindrance, delay or complication in the maintenance of the Platform entitles the Company to suspend or limit the Platform until further notice.</p>
                <h3>INDEMNIFICATION</h3>
                <p>The Users shall indemnify, defend, and hold harmless the Company and its subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, from and against any and all losses, liabilities, claims, suits, proceedings, penalties, interests, damages, demands, costs, and expenses (including legal and statutory fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by the Company that arise out of, result from, or in connection with:
                    the User’s breach of these Terms;
                    any claims made by any third party due to, or arising out of, or in connection with User’s use of Platform;
                    the User’s violation of any rights of another, including intellectual property rights; and
                    the User’s violation of any applicable laws. Notwithstanding anything to the contrary in these Terms, in no event shall the Company and its affiliates, if any, be liable to the User or anyone claiming through the User in respect of any subject matter of these Terms under contract, negligence, strict liability or other legal or equitable theory for any special, incidental, indirect, consequential, exemplary or punitive damages, loss of goodwill, loss of revenue, loss of opportunity, loss of anticipated profits, whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable or whether or not the Company has been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty or negligence or any other claim arising out of or in connection with the use of or access of the Application.</p>
                <h3>SEVERABILITY</h3>
                <p>In the event any provision of these Terms, Policies or the is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
                <h3>TERMINATION</h3>
                <p>These Terms are effective unless and until terminated by either the Company or the Users. The Users may terminate these Terms of Service at any time by notifying the Company that they no longer wish to use the Application or the Services, or when they cease using the Application.
                    If in the Company’s sole judgment the Users fail, or it is suspected or discovered that the Users have failed to comply with any term or provision of these Terms, the Company may also terminate their access to the Application, or cease the provision of the services at any time without notice and the Users will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny the Users access to the Application or the services (or any part thereof).</p>
                <h3>ENTIRE AGREEMENT</h3>
                <p>These Terms, Policies, Privacy Policies and any policies or operating rules posted by the Company on the Application constitute the entire agreement and understanding between the Users and the Company with respect to the Application, and supersede any prior or contemporaneous agreements, communications and proposals, whether oral or written, between the Users and the Company (including, but not limited to, any prior versions of the Terms of Service).
                    Any ambiguities in the interpretation of these Terms shall not be construed against the Company.</p>
                <h3>WAIVER</h3>
                <p>The failure of the Company to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.</p>
                <h3>GOVERNING LAW AND DISPUTE RESOLUTION</h3>
                <p>These Terms are governed by the laws of India. Any action, suit, or other legal proceeding, which is commenced to resolve any matter arising under or relating to this Application, shall be subject to the jurisdiction of the courts at Delhi, India.</p>
                <h3>CONTACT INFORMATION</h3>
                <p>Questions about the Terms should be sent to us at help@zopnik.com</p>
                <h3>POLICIES</h3>
                <p>Policies related to returns/ exchanges, penalties, refunds, cancellation will be updated in the Application from time to time. The Company holds the right to change these policies as required in the Application without any permission from the Users.</p>
                <h3>NOTICES</h3>
                <p>All notices or demands to or upon the Company shall in writing and shall be deemed to be duly made when sent to Zopnik Innovations Pvt Ltd, Ground Floor, 11 Oak Drive, DLF farms, Chattarpur, 110074, New Delhi.
                    All notices or demands to or upon the Company shall in writing and shall be deemed to be duly made when sent to Zopnik Innovations Pvt Ltd, Ground Floor, 11 Oak Drive, DLF farms, Chattarpur, 110074, New Delhi.
                    Notice to a User(s) shall be deemed to be received by such User(s) if and when the Platform is able to demonstrate that communication, whether in physical or electronic form, has been sent to such User(s), or immediately upon Platform’s posting such notice on an area of the Platform that is publicly accessible.</p>
                <h3>MISCELLANEOUS</h3>
                <p>Headings for any section of these Terms are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section.</p>
                <p>The Company shall have the right to assign its obligations and duties in these Terms to any person or entity.
                    All calls to the Company are completely confidential. However, the Users’ calls may be recorded to ensure quality of service. Further, for training purpose and to ensure excellent customer service, calls from the Company may be monitored and recorded.</p>
                <p>Name: Kritidipta Lahkar, Designation: Co-Founder is the designated Grievance Officer in respect of these terms. Any complaints or concerns with regard to the Platform or any breach of these Terms or Privacy Policy can be directed to the designated Grievance Officer in writing at Zopnik Innovations Pvt Ltd, 11, Oak Drive, DLF Farms, Chattarpur, New Delhi , 110074 or through an email signed with electronic signature sent to help@zopnik.com</p>
            </div>
            <Footer/>
        </div>
    );
};

export default Terms;