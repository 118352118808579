import img1 from '../images/Rectangle 20.png'
import img2 from '../images/Rectangle 21.png'
import img3 from '../images/Rectangle 22.png'
import img4 from '../images/Rectangle 23.png'

export const sustainability = [
    {
        title: 'ReCycled Polyester',
        img: img1
    },
    {
        title: 'Natural Dyes',
        img: img2
    },
    {
        title: 'Sorbtek',
        img: img3
    },
    {
        title: 'Natural Fibre',
        img: img4
    },
]