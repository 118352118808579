import React from 'react';
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';

const Privacy = () => {
    return (
        <div>
            <NavBar />
            <div className='terms'>
                <h2>Privacy Policy</h2>
                <p>We at Zopnik, take data privacy and security extremely seriously and work to ensure that we are fully compliant with the various regulations dealing with privacy.</p>
                <p>1. For the purpose of this privacy policy, “we”, “us” and “our” means Zopnik Innovations Private Limited (“Company”). and “you”, “your” and “user” means any person who accesses or uses the services provided by our mobile application (“Zopnik”).</p>
                <p>2. In the course of registering for and availing various services we provide from time to time through Zopnik Innovations Pvt Ltd (“Services”) you may be required to give certain details, including your your name, address, contact number, email address (“User Information”). By using the Zopnik Innovations Pvt. Ltd. and/or registering yourself you authorize us to contact you via email or phone call or SMS and offer you Services you have opted for, imparting knowledge about products listed on our application, as well as for web aggregation. Additionally, by registration you authorize us to send SMS/email alerts to you for your login details and any other service requirements or advertising messages/emails.</p>
                <p>3. You acknowledge that you are disclosing User Information voluntarily. Prior to the completion of any registration process on Zopnik Innovations Pvt Ltd / or prior to availing of any Services offered on our application if you wish not to disclose any User Information you may refrain from doing so; however if you don’t provide information that is requested, it is possible that the registration process would be incomplete and/or you may not be able to avail certain of our Services.</p>
                <p>4. We are committed to protecting the privacy and confidentiality of all User Information that you may share as a user of our application. In furtherance of the confidentiality with Zopnik Innovations Pvt Ltd. which we treat User Information we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect from you. To protect your privacy and security, we will also take reasonable steps to verify your identity before granting access or making corrections. We may use third-party advertising companies and/or ad agencies to serve ads when you visit our application. These companies may use information (excluding User Information) about your visits to the application and other Web sites in order to provide advertisements on the application and other sites about goods and services that may be of interest to you. There might be affiliates or other sites linked to our application and information that you provide to those sites are not our property. The affiliated sites may have different privacy practices and we encourage you to read them.</p>
                <p>5. Additionally, when you use the Site, we collect certain non-personal identifiable information through technology and tools, including cookies, etc. We aggregate and analyze this information in order to learn more about how our application is used, to improve our Zopnik Innovations Pvt Ltd, and to generally promote the Zopnik Innovations Pvt Ltd and our Services. We may engage the services of a third party for the purposes of collection of such information.</p>
                <p>6. We will not sell or rent User Information to anyone other than as specifically noted. We will share User Information if we have your consent or deemed consent to do so or if we are compelled by law (including court orders) to do so or as specified in the Terms of Use</p>
                <p>7. Notwithstanding anything mentioned above, we reserve the right to contact you via any mechanisms, not limited to email, SMS, or phone calls, to solicit feedback of your Zopnik experience and to provide any additional services that you would be eligible for, either as a registered user or as a visitor. We are not liable for any damages occurring to you as part of feedback solicitation and additional service provision.</p>
                <p>8. The User Information is being collected by the Company with its address at Zopnik Innovations Pvt Ltd, Plot no 11, Ground Floor, Oak Drive, DLF Farms , Delhi, 110074 and the Company will delete any User Information upon the User withdrawing the consent in writing, however, upon the withdrawal of the consent by the User, the Company may at its option not provide any services for which the Information was sought and the User shall not claim deficiency of services on the basis of such non provision of goods and services.</p>
                <p>9. We reserve the right to amend this policy from time to time, at our discretion. Any updates will be posted on the application/ and your use of Zopnik/ after such amendment shall constitute your agreement to abide by the amended terms. If you have questions or concerns about our Privacy Policy or any other policies please contact help@zopnik.com.</p>
            </div>
            <Footer />
        </div>
    );
};

export default Privacy;