import React from 'react';
import './Header.css'
import NavBar from '../NavBar/NavBar';

const Header = () => {
    return (
        <section className='fullHeader'>
            <NavBar/>
            <div className="header">
                <div className='headerContentBox'>
                    <h2>B2B Design to Delivery Platform
                        for Fashion Brands</h2>
                    <p>360 Degree Solution from research, design, 
                        development till delivery in a single interface.
                    </p>
                    <a href="#contact"><button>Contact Us</button></a>
                </div>
            </div>
        </section>
    );
};

export default Header;