import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Sustainability.css'
import { sustainability } from '../../data/sustainability';

const Sustainability = () => {
    return (
        <section id="sustainability">
            <Container>
                <h2 className="secHeader">Sustainability & Care for the Globe</h2>
                <p className="secP">Sustainability is at the core of our business and strive to maintain it by collaborating with partners who think and practice growth in a sustainable manner</p>
                <Row>
                    {
                        sustainability.map(({title, img}) => (
                            <Col lg={3} md={6} className='mt-4'>
                                <div className='sustainableCard'>
                                    <h5>{title}</h5>
                                    <img src={img} alt="" className='img-fluid'/>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </section>
    );
};

export default Sustainability;