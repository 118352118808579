import React from 'react';
import './Services.css'
import { services } from '../../data/service';
import FeatureCard from './FeatureCard';
import { Container } from 'react-bootstrap';

const Services = () => {
    return (
        <section id="services">
            <Container>
                <h2 className='secHeader'>Our services</h2>
                <p className='secP'>Choose us for the best of the services that you will require to expand your business</p>
                <div className='featuredBox'>
                    {
                        services.map((data, index) => <FeatureCard key={index} data={data} id={index}/>)
                    }
                </div>
            </Container>
        </section>
    );
};

export default Services;