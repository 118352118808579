import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './pages/Home/Home';
import Privacy from '../src/pages/Privacy/Privacy'
import Terms from '../src/pages/Terms/Terms'

function App() {
  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path="/">
              <Home/>
            </Route>
            <Route path="/privacy&policy">
              <Privacy/>
            </Route>
            <Route path="/terms&condition">
              <Terms/>
            </Route>
          </Switch>
       </Router>

    </div>
  );
}

export default App;
