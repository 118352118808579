import React from 'react';
import Contact from '../../components/Contact/Contact';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Partner from '../../components/Partner/Partner';
import Services from '../../components/Services/Services';
import Sustainability from '../../components/Sustainability/Sustainability';
import './Home.css'

const Home = () => {
    return (
        <div>
            <Header/>
            <Services/>
            <Partner/>
            <Sustainability/>
            <Contact/>
            <Footer/>
        </div>
    );
};

export default Home;